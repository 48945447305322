import { Flex } from '@components/base/Flex'
import styled from 'styled-components'
import { breakpoints } from '../../styles/utils'
import BookingCard from './BookingCard'

export default styled(Flex)<{ alignSelf?: 'left' | 'right' }>`
    align-self: ${({ alignSelf }) => (alignSelf === 'right' ? 'flex-end' : 'flex-start')};
    gap: 20px;
    padding-top: 20px;
    flex-wrap: wrap;
    @media (max-width: ${breakpoints.md}px) {
        flex-direction: column;
        align-self: stretch;
        ${BookingCard} {
            //width: 100%;
        }
    }
`
