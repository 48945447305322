import * as React from 'react'
import Layout from '@components/Layout/Layout'
import { Text } from '@components/base/Text'
import OverlappingSection from '@components/OverlappingSection'
import BookingContent from '@components/Booking/BookingContent'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'

const Booking = () => {
    const { t } = useTranslation()
    return (
        <Layout layoutType={'fullScreen'} occupySpace={false}>
            <OverlappingSection
                Header={
                    <Text bolded type={'h1'}>
                        {t('menu:booking')}
                    </Text>
                }
                Body={<BookingContent />}
            />
        </Layout>
    )
}

export const pageQuery = graphql`
    query BookingPageTemplate($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

export default Booking
