import { Flex } from '@components/base/Flex'
import styled from 'styled-components'

export default styled(Flex)`
    min-width: 100px;
    padding: 0 20px;
    height: 80px;
    text-align: center;
    border-radius: 5px;
    justify-content: center;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    &:hover {
        background-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.accent};
    }

    &[data-active='true'] {
        background-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.accent};
    }
`
