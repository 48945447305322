import BookingStep from '@components/Booking/BookingStep'
import { Flex } from '@components/base/Flex'
import * as React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Text } from '@components/base/Text'
import { AccentButton } from '@components/base/Button'
import styled from 'styled-components'
import BookingCardContainer from '@components/Booking/BookingCardContainer'
import BookingCard from '@components/Booking/BookingCard'
import { useState } from 'react'
import DayPicker from 'react-day-picker'
import { TextInput } from '@components/base/TextInput'
import { InputContainer } from '@pages/contact'
import { breakpoints } from '../../styles/utils'
import { useTranslation } from 'react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'
import axios from 'axios'

type BookingForm = {
    type: 'booking:stay' | 'booking:event' | ''
    guestCount: string
    startDate: Date
    endDate: Date
    paymentMethod: string
    phoneNumber: string
    fullName: string
    'form-name': string;
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`
const Modal = styled(Flex)`
    position: fixed;
    z-index: 1;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
    background: white;
`

const PersonalDetailsSection = styled(Flex)`
    gap: 20px;

    @media (max-width: ${breakpoints.md}px) {
        flex-direction: column;
    }
`

export default () => {
    const { t } = useTranslation()
    const { language } = useI18next()
    const [datePicker, setDatePicker] = useState<undefined | 'startDate' | 'endDate'>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const onSubmit = async (values) => {
        let myForm = document.getElementById("booking");
        let formData = new FormData(myForm);
        await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({...formData, values}).toString(),
        });
        setSubmitted(true);
        return null;
    }

    const { submitForm, setFieldValue, getFieldProps, values, isValid, isSubmitting, dirty } = useFormik<BookingForm>({
        initialValues: {
            type: '',
            guestCount: '',
            startDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
            endDate: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 2),
            paymentMethod: '',
            phoneNumber: '',
            fullName: '',
            'form-name': 'booking'
        },
        validationSchema: Yup.object({
            type: Yup.string().required(),
            guestCount: Yup.string().required(),
            startDate: Yup.date().required(),
            endDate: Yup.date().required(),
            paymentMethod: Yup.string().required(),
            phoneNumber: Yup.string().required(),
            fullName: Yup.string().required(),
        }),
        onSubmit,
    })

    return (
        <>
            {submitted &&<Text type={'body'} centered>Submission Sent - We will be in touch.</Text>}

            {!submitted && <StyledForm onSubmit={submitForm} id="booking"  name="booking" method="POST" data-netlify="true">
            {datePicker && (
                <Modal>
                    <input type="hidden" name="form-name" value="booking" />
                    <DayPicker
                        showOutsideDays={false}
                        disabledDays={{
                            before: datePicker === 'startDate' ? new Date() : new Date(values.startDate),
                        }}
                        modifiers={{
                            highlighted: {
                                from: values.startDate,
                                to: values.endDate,
                            },
                        }}
                        onDayClick={(day, { disabled }) => {
                            if (!disabled) {
                                setFieldValue(datePicker, day)
                                setDatePicker(undefined)
                            }
                        }}
                    />
                    <AccentButton onClick={() => setDatePicker(undefined)}>{t('close')}</AccentButton>
                </Modal>
            )}
            <BookingStep
                question={t('booking:type')}
                isActive={values.type}
                onOptionClick={(type) => setFieldValue('type', type)}
                options={['booking:stay', 'booking:event']}
            />

            {values.type === 'booking:stay' && (
                <>
                    <BookingStep
                        isActive={values.guestCount}
                        onOptionClick={(guestCount) => setFieldValue('guestCount', guestCount)}
                        question={t('booking:booking-for-who')}
                        options={[
                            'booking:self',
                            'booking:self_plus_1',
                            'booking:self_plus_2',
                            'booking:plus_4',
                            'booking:conference',
                        ]}
                    />
                    <Flex padding={'medium'} direction={'column'}>
                        <Text type={'h3'}>{t('booking:personal-information')}</Text>
                        <PersonalDetailsSection direction={'row'}>
                            <InputContainer direction={'row'} alignItems={'center'} flex={1}>
                                <Text color={'background'} bolded>
                                    {t('booking:full-name')}
                                </Text>
                                <TextInput {...getFieldProps('fullName')} fullWidth placeholder={'John Doe'} />
                            </InputContainer>
                            <InputContainer direction={'row'} alignItems={'center'} flex={1}>
                                <Text color={'background'} bolded>
                                    {t('booking:phone_number')}
                                </Text>
                                <TextInput
                                    {...getFieldProps('phoneNumber')}
                                    fullWidth
                                    placeholder={'+970 000 000 0000'}
                                />
                            </InputContainer>
                        </PersonalDetailsSection>
                    </Flex>
                    <Flex padding={'medium'} direction={'column'}>
                        <Text type={'h3'}>{t('booking:checkin-checkout')}</Text>
                        <BookingCardContainer
                            alignSelf={language === 'en' ? 'left' : 'right'}
                            direction={language === 'en' ? 'row' : 'row-reverse'}
                            alignItems={'center'}
                        >
                            <BookingCard flex={1} onClick={() => setDatePicker('startDate')}>
                                <Text>{values.startDate.toLocaleDateString()} @ 3:00 pm</Text>
                                <Text type={'hint'}>{t('booking:press-to-set')}</Text>
                            </BookingCard>
                            <Text>{t('booking:until')}</Text>
                            <BookingCard flex={1} onClick={() => setDatePicker('endDate')}>
                                <Text>{values.endDate.toLocaleDateString()} @ 11:00 am</Text>
                                <Text type={'hint'}>{t('booking:press-to-set')}</Text>
                            </BookingCard>
                        </BookingCardContainer>
                    </Flex>
                    <BookingStep
                        isActive={values.paymentMethod}
                        onOptionClick={(paymentMethod) => setFieldValue('paymentMethod', paymentMethod)}
                        question={t('booking:payment-method')}
                        options={['booking:visa-mastercard', 'booking:cash']}
                    />
                </>
            )}
            <AccentButton disabled={!isValid || isSubmitting || !dirty} onClick={submitForm}>
                {t('booking:submit-form')}
            </AccentButton>
        </StyledForm>}
            </>
    )
}
