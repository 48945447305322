import * as React from 'react'
import { Flex } from '@components/base/Flex'
import { Text } from '@components/base/Text'
import BookingCard from '@components/Booking/BookingCard'
import BookingCardContainer from '@components/Booking/BookingCardContainer'
import { useTranslation } from 'react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'

type BookingStepProps = {
    question: string
    options: string[]
    isActive?: string | null
    onOptionClick?: (option: string) => void
}

export default ({ question, options, isActive, onOptionClick }: BookingStepProps) => {
    const { t } = useTranslation()
    const { language } = useI18next()
    return (
        <Flex padding={'medium'} direction={'column'}>
            <Text type={'h3'}>{question}</Text>
            <BookingCardContainer
                alignSelf={language === 'en' ? 'left' : 'right'}
                direction={language === 'en' ? 'row' : 'row-reverse'}
            >
                {options.map((option) => (
                    <BookingCard
                        onClick={() => onOptionClick && onOptionClick(option)}
                        data-active={isActive?.toString() === option.toString()}
                    >
                        <Text>{t(option)}</Text>
                    </BookingCard>
                ))}
            </BookingCardContainer>
        </Flex>
    )
}
